









import { Component, Mixins } from 'vue-property-decorator'
import NavMenu from '@/partials/components/NavMenu.vue'

import PermissionsMixin from '@/mixins/permissionsMixin'

@Component({
  components: {
    NavMenu
  }
})
export default class QuestionsMenuLayout extends Mixins(PermissionsMixin) {
  availableRoutes: AvailableRoute[] = []
  created () {
    this.availableRoutes = [
      {
        label: 'Questões',
        path: '/questions',
        permission: this.permissions.QUESTION.READ
      },
      {
        label: 'Rascunhos',
        path: '/questions/drafts',
        permission: this.permissions.QUESTIONDRAFT.READ
      },
      {
        label: 'Temas',
        path: '/questions/topics',
        permission: this.permissions.TOPIC.READ
      }
    ]
  }
}
